
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { config } from "@/core/helpers/config";
import { getIllustrationsPath } from "@/core/helpers/assets";
export default defineComponent({
  name: "sign-up",
  components: {},
  data() {
    return {};
  },
  methods: {
    signIn() {
      config.value.illustrations.set = "sketchy-1";
      this.router.push({ name: "sign-in" });
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      //config.value.illustrations.set = "dozzy-1";
    });

    return {
      config,
      getIllustrationsPath,
      router,
    };
  },
});
